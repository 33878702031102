import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import Loadable from './Loadable';

import ThemeRoutes from './themeroutes';

// ----------------------------------------------------------------------

const route1 = [];

ThemeRoutes.map((route) => {
  const route2 = [];
  if (route.child)
    route.child.map((r2) => {
      if (r2.element) route2.push({ path: r2.path, element: r2.element });
      else {
        const route3 = [];
        r2.subchild.map((r3) => {
          route3.push({ path: r3.path, element: r3.element });
          return true;
        });
        route2.push({ path: r2.path, children: route3 });
        return true;
      }
      return true;
    });
  route1.push({ path: route.path, children: route2 });
  return true;
});

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'verify',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          )
        },
        {
          path: 'onboarding',
          element: (
            <AuthGuard>
              <Onboarding />
            </AuthGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    { path: '/', element: <Navigate to="/manage/dashboard" replace /> },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [...route1]
    },

    { path: '404', element: <Page404 /> },
    { path: '*', element: <Page404 /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Register = Loadable(lazy(() => import('pages/authentication/Register')));
const Onboarding = Loadable(lazy(() => import('pages/authentication/Onboarding')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('pages/authentication/VerifyCode')));
const Page404 = Loadable(lazy(() => import('pages/Page404')));

import { createSlice } from '@reduxjs/toolkit';
import { instance } from 'utils/axios';

const initialState = {
  groups: [],
  noOfRecords: 0,
  group: {},
  isFetchGroupsPending: false,
  isFetchGroupPending: false,
  isAddUpdateGroupPending: false,
  isDeleteSuccess: false
};

const slice = createSlice({
  name: 'websiteGroup',
  initialState,
  reducers: {
    fetchAllGroups(state, action) {
      state.allgroups = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchGroups(state, action) {
      state.groups = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchGroup(state, action) {
      state.group = action.payload;
    },
    setFetchGroupsPending(state, action) {
      state.isFetchGroupsPending = action.payload;
    },
    setFetchGroupPending(state, action) {
      state.isFetchGroupPending = action.payload;
    },
    setAddUpdateGroupsPending(state, action) {
      state.isAddUpdateGroupPending = action.payload;
    },
    setDeleteSuccess(state, action) {
      state.isDeleteSuccess = action.payload;
    }
  }
});

export const { reducer } = slice;

export const fetchGroups =
  (pageNo = 1, perPage = 10, keyword = '', order = '', desc = false) =>
  async (dispatch) => {
    dispatch(slice.actions.setDeleteSuccess(false));
    dispatch(slice.actions.setFetchGroupsPending(true));
    dispatch(slice.actions.fetchGroups({ items: [], count: -1 }));

    const response = await instance
      .post(`/groups/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? ' desc' : ''}`, {})
      .catch((error) => {
        console.log(error);
        if (error.response) {
          // CustomSnackbar('error', err.response.data.data);
        }
      });
    dispatch(slice.actions.setFetchGroupsPending(false));
    if (response) {
      dispatch(slice.actions.fetchGroups(response.data.data));
    }
  };

export const fetchAllGroups = () => async (dispatch) => {
  dispatch(slice.actions.setFetchGroupsPending(true));
  dispatch(slice.actions.fetchGroups({ items: [], count: -1 }));

  const response = await instance.post('websites/groups/get', {}).catch((err) => {
    if (err.response) {
      // CustomSnackbar('error', err.response.data.data);
    }
  });
  dispatch(slice.actions.setFetchGroupsPending(false));
  if (response) {
    dispatch(
      slice.actions.fetchGroups({
        items: response.data.data,
        count: response.data.data.length
      })
    );
    dispatch(
      slice.actions.fetchAllGroups({
        items: response.data.data,
        count: response.data.data.length
      })
    );
  }
};

export const fetchGroup = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchGroupPending(true));

  const response = await instance.post(`helpdesk/groups/get/${id}`, {}).catch((err) => {
    if (err.response) {
      // CustomSnackbar('error', err.response.data.data);
    }
  });
  dispatch(slice.actions.setFetchGroupPending(false));
  if (response) {
    dispatch(slice.actions.fetchGroup(response.data.data));
  }
};

export const addGroup = (postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateGroupsPending(true));

  const response = await instance.post('helpdesk/groups/create', postData).catch((err) => {
    if (err.response) {
      // CustomSnackbar('error', err.response.data.data);
    }
  });
  dispatch(slice.actions.setAddUpdateGroupsPending(false));
  if (response) {
    toggle();
    // CustomSnackbar('success', 'Group added successfully');
    dispatch(fetchGroups());
  }
};

export const updateGroup = (id, postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateGroupsPending(true));

  const response = await instance.post(`helpdesk/groups/update/${id}`, postData).catch((err) => {
    if (err.response) {
      // CustomSnackbar('error', err.response.data.data);
    }
  });
  dispatch(slice.actions.setAddUpdateGroupsPending(false));
  if (response) {
    toggle();
    // CustomSnackbar('success', 'Group updated successfully');
    dispatch(fetchGroups());
  }
};

export const deleteGroups =
  (postData, pageNo, perPage, keyword = '') =>
  async (dispatch) => {
    dispatch(slice.actions.setDeleteSuccess(false));
    const response = await instance.post('groups/delete', postData).catch((err) => {
      if (err.response) {
        dispatch(slice.actions.setDeleteSuccess(false));
      }
    });
    if (response) {
      dispatch(slice.actions.setDeleteSuccess(true));
      dispatch(fetchGroups(pageNo, perPage, keyword));
    }
  };

export default slice;

import { createSlice } from '@reduxjs/toolkit';
import { instance } from 'utils/axios';

const initialState = {
  allwebsites: [],
  servers: [],
  websites: [],
  noOfRecords: 0,
  website: {},
  isFetchWebsitesPending: false,
  fetchWebsiteError: null,
  isFetchWebsitePending: false,
  isDeleteWebsitesPending: false,
  isDeleteWebsiteSuccess: false,
  deleteWebsiteError: null
};

const slice = createSlice({
  name: 'website',
  initialState,
  reducers: {
    fetchAllServers(state, action) {
      state.servers = action.payload.data;
    },
    fetchAllWebsites(state, action) {
      state.allwebsites = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchWebsites(state, action) {
      state.websites = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    setFetchWebsiteError(state, action) {
      state.fetchWebsiteError = action.payload;
    },
    fetchWebsite(state, action) {
      state.website = action.payload;
    },
    setFetchWebsitesPending(state, action) {
      state.isFetchWebsitesPending = action.payload;
    },
    setFetchWebsitePending(state, action) {
      state.isFetchWebsitePending = action.payload;
    },
    setDeleteWebsitesPending(state, action) {
      state.isDeleteWebsitesPending = action.payload;
    },
    setDeleteWebsiteSuccess(state, action) {
      state.isDeleteWebsiteSuccess = action.payload;
    },
    setDeleteError(state, action) {
      state.deleteWebsiteError = action.payload;
    }
  }
});

export const { reducer } = slice;

export const fetchWebsites =
  (pageNo = 1, perPage = 10, keyword = '', order = '', desc = false) =>
  async (dispatch) => {
    dispatch(slice.actions.setDeleteWebsiteSuccess(false));
    dispatch(slice.actions.setDeleteError(null));
    dispatch(slice.actions.setFetchWebsiteError(null));
    dispatch(slice.actions.setFetchWebsitesPending(true));
    dispatch(slice.actions.fetchWebsites({ items: [], count: -1 }));

    const response = await instance
      .post(`/websites/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? ' desc' : ''}`, {})
      .catch((error) => {
        if (error.response) {
          dispatch(slice.actions.setFetchWebsiteError(error.response.data.data));
        }
      });
    if (response) {
      dispatch(slice.actions.fetchWebsites(response.data.data));
    }
    dispatch(slice.actions.setFetchWebsitesPending(false));
  };

export const fetchAllWebsites = () => async (dispatch) => {
  dispatch(slice.actions.fetchAllWebsites({ items: [], count: -1 }));

  const response = await instance.post('websites/get', {});
  if (response) {
    dispatch(
      slice.actions.fetchAllWebsites({
        items: response.data.data,
        count: response.data.data.length
      })
    );
  }
};

export const fetchWebsite = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchWebsitePending(true));
  dispatch(slice.actions.setFetchWebsiteError(null));

  const response = await instance.post(`websites/get/${id}`, {}).catch((err) => {
    if (err.response) {
      dispatch(slice.actions.setFetchWebsiteError(err.response.data.data));
    }
  });
  dispatch(slice.actions.setFetchWebsitePending(false));
  if (response) {
    dispatch(slice.actions.fetchWebsite(response.data.data));
  }
};

export const addWebsite = (postData) => async () => {
  const response = await instance.post('websites/create', postData);

  return response;
};

export const updateWebsite = (id, postData) => async () => {
  const response = await instance.post(`websites/update/${id}`, postData);

  return response;
};

export const deleteWebsites =
  (postData, pageNo, perPage, keyword = '') =>
  async (dispatch) => {
    dispatch(slice.actions.setDeleteWebsiteSuccess(false));
    dispatch(slice.actions.setDeleteWebsitesPending(true));
    const response = await instance.post('websites/delete', postData).catch((err) => {
      if (err.response) {
        console.log(err.response.data.data);
        dispatch(slice.actions.setDeleteError(err.response.data.data));
      }
    });
    dispatch(slice.actions.setDeleteWebsitesPending(false));
    if (response) {
      dispatch(slice.actions.setDeleteWebsiteSuccess(true));
      dispatch(fetchWebsites(pageNo, perPage, keyword));
      return response;
    }
  };

// servers api
export const fetchAllServers = () => async (dispatch) => {
  const response = await instance.post('servers/get', {});
  if (response) {
    dispatch(
      slice.actions.fetchAllServers({
        data: response.data.data
      })
    );
  }
};

export default slice;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Drawer, Divider } from '@material-ui/core';
// components
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import NavSection from 'components/NavSection';
import { MHidden } from 'components/@material-extend';
//
import ThemeRoutes from 'routes/themeroutes';

import { DRAWER_WIDTH } from 'theme/constants';
import SiteSelector from './SiteSelector';

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 320;  Now Coming from theme/constants
const APPBAR_MOBILE = 57;
const APPBAR_DESKTOP = 57;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const BrandStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    height: APPBAR_DESKTOP
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const renderBrand = (
    <>
      <BrandStyle>
        <Box display="flex" flexDirection="row" height="100%">
          <Box component={RouterLink} to="/" sx={{ px: 1.2, py: 1.2, display: 'inline-flex' }} width="58px">
            <Logo />
          </Box>
          <Box width="100%" p={2} sx={{ px: 1.5, py: 0.6, display: 'inline-flex' }}>
            <SiteSelector />
          </Box>
        </Box>
      </BrandStyle>
    </>
  );
  const renderContent = (
    <>
      {renderBrand}
      <Divider />
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <NavSection navConfig={ThemeRoutes} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {/* For Mobile */}
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      {/* For Desktop */}
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              borderRight: 'none',
              boxShadow: '0 1px 2px rgb(0 0 0 / 20%)'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

import { Link as ScrollLink } from 'react-scroll';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
// material
import { Box, Link, Container, Typography, Divider } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import Scrollbar from 'components/Scrollbar';
import { DRAWER_WIDTH } from 'theme/constants';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Logo from '../../components/Logo';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 58;
const APP_BAR_DESKTOP = 58;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  position: 'relative',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const BoxStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <Scrollbar>
        <RootStyle>
          <BoxStyle />
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <MainStyle>
            <Box mb={10}>
              <Outlet />
            </Box>
            <Box
              sx={{
                mb: 5,
                textAlign: 'center',
                position: 'absolute',
                width: '98%',
                bottom: 0,
                bgcolor: 'background.default'
              }}
            >
              <Container maxWidth="lg">
                {/* <ScrollLink to="move_top" spy smooth>
                  <Logo sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
                </ScrollLink> */}
                <Divider />
                <Typography variant="caption" component="p">
                  <br />© 2021 All rights reserved. Designed and developed by{' '}
                  <Link href="https://centyr.com/">Centyr</Link>.
                </Typography>
              </Container>
            </Box>
          </MainStyle>
        </RootStyle>
      </Scrollbar>
    </>
  );
}

// routes
import Router from './routes';
// theme
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <>
      <Settings />
      <ScrollToTop />
      <GoogleAnalytics />
      {isInitialized ? <Router /> : <LoadingScreen />}
    </>
  );
}

import { combineReducers } from 'redux';
// slices
import { mainWebsiteReducer } from 'app/manage/website/slices';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  ...mainWebsiteReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer
});

export default rootReducer;
